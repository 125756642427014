import { Controller } from "@hotwired/stimulus"
import StiPhotoFields from "../../src/general/fields/photo_fields.js"

// Connects to data-controller="form--photos"
export default class extends Controller {
  static values = {
    photoType: String,
    imageableId: String,
    imageableType: String,
    imageableName: String
  }

  connect() {
    new StiPhotoFields({
      container: this.element,
      photoType: this.photoTypeValue,
      imageableId: this.imageableIdValue,
      imageableType: this.imageableTypeValue,
      imageableName: this.imageableNameValue
    });
  }
}
